// src/components/LoginPage.jsx
import React from 'react';

const LoginPage = () => {
  return (
    <div>
      <h1>Login</h1>
      {/* Implementar el formulario de login aquí */}
    </div>
  );
};

export default LoginPage;
