// src/components/HomePage.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import './HomePage.css'; // Asegúrate de que la ruta CSS es correcta
import logo from './red-social-diversidad-kik-lov-transparent.png'; 


const HomePage = () => {
  return (
    <div className="homepage">
      <header className="header">
        <img src={logo} alt="logo de la red social de la diversidad" className="logo" /> 
      </header>
      <main className="main-content">
        <h1>Bienvenido a la red social más abierta a la humanidad</h1>
        <div className="links">
          <Link to="/register" className="link-button">Registro</Link>
          <Link to="/login" className="link-button">Login</Link>
          <Link to="/privacy-policy" className="link-button">Política de Privacidad</Link>
        </div>
      </main>
    </div>
  );
};

export default HomePage;
