import React from 'react';

const PrivacyPolicyPage = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Política de Privacidad</h1>
      <p style={styles.paragraph}>
        <strong>Fecha de entrada en vigor:</strong> 07/09/2024
      </p>
      <p style={styles.paragraph}>
        <strong>1. Introducción</strong><br />
        Bienvenido a Kiklov. Esta Política de Privacidad explica cómo recopilamos, usamos, divulgamos y protegemos la información personal que obtenemos a través de nuestra aplicación y sitio web. Al utilizar nuestros servicios, aceptas la recopilación y el uso de tu información de acuerdo con esta política.
      </p>
      <p style={styles.paragraph}>
        <strong>2. Información que Recopilamos</strong><br />
        Recopilamos información personal que nos proporcionas directamente cuando te registras en nuestra aplicación, inicias sesión o interactúas con nuestras funciones. Esta información puede incluir:
        <ul>
          <li>Nombre</li>
          <li>Correo electrónico</li>
          <li>Información de perfil de Facebook (si utilizas Facebook Login)</li>
          <li>Datos relacionados con tu uso de la aplicación</li>
        </ul>
      </p>
      <p style={styles.paragraph}>
        <strong>3. Uso de la Información</strong><br />
        Utilizamos la información que recopilamos para los siguientes fines:
        <ul>
          <li>Proporcionar y mejorar nuestros servicios.</li>
          <li>Personalizar tu experiencia en la aplicación.</li>
          <li>Comunicarnos contigo sobre actualizaciones, ofertas y noticias relacionadas con Kiklov.</li>
          <li>Procesar tus solicitudes y responder a tus preguntas.</li>
          <li>Cumplir con requisitos legales y normativos.</li>
        </ul>
      </p>
      <p style={styles.paragraph}>
        <strong>4. Compartición de la Información</strong><br />
        No compartimos tu información personal con terceros, excepto en los siguientes casos:
        <ul>
          <li>Con tu consentimiento explícito.</li>
          <li>Para cumplir con la ley, regulaciones o procedimientos legales.</li>
          <li>Con proveedores de servicios que trabajan en nuestro nombre para proporcionar y mejorar nuestros servicios.</li>
        </ul>
      </p>
      <p style={styles.paragraph}>
        <strong>5. Seguridad de la Información</strong><br />
        Implementamos medidas de seguridad razonables para proteger tu información personal contra accesos no autorizados, divulgación, alteración o destrucción. Sin embargo, ninguna medida de seguridad es infalible, y no podemos garantizar la seguridad absoluta de tu información.
      </p>
      <p style={styles.paragraph}>
        <strong>6. Tus Derechos</strong><br />
        Tienes el derecho a acceder, corregir o eliminar tu información personal. Si deseas ejercer estos derechos, por favor contáctanos a través de la dirección de correo electrónico proporcionada a continuación.
      </p>
      <p style={styles.paragraph}>
        <strong>7. Cambios a esta Política</strong><br />
        Podemos actualizar esta Política de Privacidad de vez en cuando. Te notificaremos sobre cualquier cambio significativo mediante la publicación de la nueva política en nuestro sitio web. Te recomendamos revisar esta política periódicamente para estar informado sobre cómo protegemos tu información.
      </p>
      <p style={styles.paragraph}>
        <strong>8. Contacto</strong><br />
        Si tienes preguntas o inquietudes sobre esta Política de Privacidad, por favor contáctanos a:
        <br />
        <strong>Correo electrónico:</strong> cristianca84@gmail.com<br />
        
      </p>
    </div>
  );
};

// Estilos en línea para el componente
const styles = {
  container: {
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
    fontFamily: 'Arial, sans-serif',
    color: '#333'
  },
  heading: {
    textAlign: 'center',
    color: '#4f1e56',
    marginBottom: '20px'
  },
  paragraph: {
    marginBottom: '15px',
    lineHeight: '1.6'
  }
};

export default PrivacyPolicyPage;
